import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout } from './components/Layout';
import Reports from './components/Reports';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import ReportDetails from './components/ReportDetails';
import Schedule from './components/Schedule';
import ScheduleDetails from './components/ScheduleDetails';
import ScheduleAdd from './components/ScheduleAdd';
import Help from './components/Help';
import Loading from './components/Loading';
import UserManagement from './components/UserManagement';
import UserDetails from './components/UserDetails';
import ChangePassword from './components/ChangePassword';
import ForgotPassword from './components/ForgotPassword';
import ConfirmRegistration from './components/ConfirmRegistration';
import ResetPassword from './components/ResetPassword';
import ReactGA from 'react-ga4';
import LoginHelp from './components/LoginHelp';
import NotFound from './components/NotFound';
import Print from './components/Print';
import FedAuth from './components/FedAuth';
import EnterpriseLogin from './components/EnterpriseLogin';
import PrivateExpiredRoute from './components/PrivateExpiredRoute';
import Settings from './components/Settings';
import Auth from './services/auth';
import Worklist from './components/Worklist';
import WorklistDetails from './components/WorklistDetails';
import TeamStatus from './components/TeamStatus';
import StatusDetails from './components/StatusDetails';
import LastImportDate from './components/LastImportDate';
import WorkFlowManagement from './components/WorkFlowManagement';
import Ivlist from './components/Ivlist';
import IvlistDetails from './components/IvlistDetails';
import PriorityDelinquentDashboard from './components/PriorityDelinquentDashboard';
import ProductivityDashboard from './components/ProductivityDashboard';
import PASWizard from './components/PASWizard';
import EomWizard from './components/EomWizard';
import EomWizardBK from './components/EomWizardBK';

/*import TagManager from 'react-gtm-module-nonce';*/

const auth = new Auth();

function CreateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

ReactGA.initialize('G-6DE92R1BS6', { debug: true });

//var nonceValue = CreateUUID();

//const tagManagerArgs = {
//    gtmId: 'G-6DE92R1BS6', nonce: nonceValue
//};

//TagManager.initialize(tagManagerArgs);

//uncomment this for debugging
/*ReactGA.initialize('UA-39997333-1', { debug: true });*/

const logPageView = () => {

    ReactGA.set({ page: window.location.pathname });
    //custom dimension, selected account
    ReactGA.set({ account: auth.getActiveAccount() });

    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    return null;
};

export default class App extends Component {
    displayName = App.name

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            statusFilters: [],
            isLoading: false,
            openSnack: false,
            snackMessage: "",
            btnActionedState: true,
            tickler: auth.getTickler() ? auth.getTickler() : 1,
            actioned: "0",
            actionedTypes: [],
            ticklerTypes: [],
            isThereData: true,
        };

        this.gridRef = React.createRef();
    }

    render() {
        return (
            <Layout>
                <Route component={logPageView} />
                <Switch>
                    <PrivateRoute exact path='/' component={Dashboard} />
                    <PrivateRoute exact path='/reports/search/:searchTerm' component={Reports} />
                    <PrivateRoute exact path='/reports/:status?/:dateType?' component={Reports} />
                    <PrivateRoute exact path='/schedule/:status?' component={Schedule} />
                    <PrivateRoute exact path='/reportdetails/:account/:jobid/:mode?' component={ReportDetails} />
                    <PrivateRoute exact path='/scheduledetails/:account/:patientListId' component={ScheduleDetails} />
                    <PrivateRoute exact path='/scheduleadd' component={ScheduleAdd} />
                    <PrivateRoute exact path='/Settings' component={Settings} />
                    <Route path='/login/:classic?' component={Login} />
                    <Route path='/loading' component={Loading} />
                    <Route path='/forgotpassword' component={ForgotPassword} />
                    <Route exact path='/confirmregistration/:code' component={ConfirmRegistration} />
                    <PrivateRoute exact path='/Users' component={UserManagement} />
                    <PrivateRoute exact path='/userdetails/:userId' component={UserDetails} />
                    <PrivateRoute exact path='/help' component={Help} />
                    <PrivateExpiredRoute exact path='/ChangePassword' component={ChangePassword} />
                    <Route exact path='/resetPassword/:code' component={ResetPassword} />
                    <Route path='/loginHelp' component={LoginHelp} />
                    <PrivateRoute path='/print/:printKey' component={Print}/>
                    <Route path='/fedAuth' component={FedAuth} />
                    <Route path='/enterpriselogin' component={EnterpriseLogin} />
                    <PrivateRoute exact path='/Worklist/:client?' component={Worklist} />
                    <PrivateRoute exact path='/TeamStatus' component={TeamStatus} />
                    <PrivateRoute exact path='/StatusDetails/:FacilityId/:AccVst' component={StatusDetails} />
                    <PrivateRoute exact path='/worklistdetails/:account/:caseid/:id' component={WorklistDetails} />
                    <PrivateRoute exact path='/lastImportDate/' component={LastImportDate} />
                    <PrivateRoute exact path='/WorkFlowManagement/' component={WorkFlowManagement} />
                    <PrivateRoute exact path='/IVlist' component={Ivlist} />
                    <PrivateRoute exact path='/ivlistdetails/:account/:id' component={IvlistDetails} />
                    <PrivateRoute exact path='/PriorityDelinquentDashboard' component={PriorityDelinquentDashboard} />
                    <PrivateRoute exact path='/ProductivityDashboard' component={ProductivityDashboard} />
                    <PrivateRoute exact path='/PASWizard' component={PASWizard} />
                    <PrivateRoute exact path='/EomWizard' component={EomWizard} />
                    <PrivateRoute exact path='/EomWizardBK' component={EomWizardBK} />
                    <Route component={NotFound} />
                </Switch>
            </Layout>
        );
    }
}
