import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import withStyles from '@material-ui/core/styles/withStyles';
//import { createTheme } from '@material-ui/core/styles'
import { Grid, GridColumn, GridToolbar, GridDetailRow } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { IntlProvider, load, LocalizationProvider, loadMessages, IntlService } from "@progress/kendo-react-intl";
import Snackbar from '@material-ui/core/Snackbar';
import ProgressIndicator from './ProgressIndicator';
import Auth from '../services/auth';
import DelinquentDashboardDataAccess from '../services/DelinquentDashboardDataAccess';
import { groupBy, filterBy, process, orderBy } from "@progress/kendo-data-query";
import { Link } from 'react-router-dom';
import moment from 'moment';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Tooltip from '@material-ui/core/Tooltip';
//import PubSub from 'pubsub-js';
//import { useContext } from 'react';
//import products from './products.json';

import {
    setExpandedState,
    getGroupIds,
    setGroupIds,
} from "@progress/kendo-react-data-tools";
import orders from "./orders.json";

class CustomGridDetailRow extends GridDetailRow {
    render() {
        const detailData = this.props.dataItem.StatusCodeDetail;
        if (detailData) {
            return (
                <div>
                    <Grid data={orderBy(detailData, [
                        {
                            field: "Count",
                            dir: "desc",
                        },
                    ])} />
                </div>
            );
        }

        return (<div>No data found</div>)
    }
}

const auth = new Auth();
const delinquentDashboardDataAccess = new DelinquentDashboardDataAccess();
const CustomCell = (props) => {


    if (props.tdProps && props.tdProps.colSpan != null) {
        props.tdProps.colSpan = 4;

    }

    return (
        props.tdProps && (

            <td
                {...props.tdProps}
                style={{
                    backgroundColor: props.color,
                }}
            >
                {props.children && (
                    <p className="k-reset">
                        {props.children.props.children[0]}
                        {props.dataItem[props.field]}
                        {props.dataItem.field === "CardName" && (
                            <Tooltip title={props.dataItem.items[0].CardDescription} placement="right" disableFocusListener>
                                <InfoOutlinedIcon fontSize="small" />
                            </Tooltip>)}
                    </p>

                )}
            </td>
        )
    );
};
const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(1),
    }),
    inputLabel: {
        paddingLeft: 2,
        marginLeft: 18,
        marginTop: -10,
        width: '100%',
    },
    formControl: {
        width: 150,
        paddingLeft: 5,
    },
});

const locales = [
    {
        language: "en-US",
        locale: "en",
    },
    {
        language: "es-ES",
        locale: "es",
    },
];

const aggregates = [
    {
        field: "Day1",
        aggregate: "sum",
    },
    {
        field: "Day2",
        aggregate: "sum",
    },
    {
        field: "Day3",
        aggregate: "sum",
    },
    {
        field: "Day4",
        aggregate: "sum",
    },
    {
        field: "Day5",
        aggregate: "sum",
    }
    ,
    {
        field: "Day6",
        aggregate: "sum",
    },
    {
        field: "Day7",
        aggregate: "sum",
    },
    {
        field: "Day8",
        aggregate: "sum",
    },
    {
        field: "Day9",
        aggregate: "sum",
    },
    {
        field: "Day10",
        aggregate: "sum",
    },
    {
        field: "Day11",
        aggregate: "sum",
    },
    {
        field: "Day12",
        aggregate: "sum",
    },
    {
        field: "Day13",
        aggregate: "sum",
    },
    {
        field: "Day14",
        aggregate: "sum",
    },
    {
        field: "Day15",
        aggregate: "sum",
    },
    {
        field: "Day16",
        aggregate: "sum",
    },
    {
        field: "Day17",
        aggregate: "sum",
    },
    {
        field: "Day18",
        aggregate: "sum",
    },
    {
        field: "Day19",
        aggregate: "sum",
    },
    {
        field: "Day20",
        aggregate: "sum",
    },
    {
        field: "Day21",
        aggregate: "sum",
    },
    {
        field: "Day22",
        aggregate: "sum",
    },
    {
        field: "Day23",
        aggregate: "sum",
    },
    {
        field: "Day24",
        aggregate: "sum",
    },
    {
        field: "Day25",
        aggregate: "sum",
    },
    {
        field: "Day26",
        aggregate: "sum",
    },
    {
        field: "Day27",
        aggregate: "sum",
    },
    {
        field: "Day28",
        aggregate: "sum",
    },
    {
        field: "Day29",
        aggregate: "sum",
    },
    {
        field: "Day30",
        aggregate: "sum",
    }
];

const initialDataState = {

    group: [
        {
            field: "CardName",
        },
    ],
};

const processWithGroups = (data, dataState) => {
    const groups = dataState.group;
    if (groups) {
        groups.map((group) => (group.aggregates = aggregates));
    }
    dataState.group = groups;
    const newDataState = process(data, dataState);
    setGroupIds({
        data: newDataState.data,
        group: dataState.group,
    });
    return newDataState;
};


class PriorityDelinquentDashboard extends Component {

    constructor(props) {
        super(props);

        this._export = null;
        this.expandedValues = [];

        this.state = {
            data: [],
            dataResult: {
                data: []
            },
            group: [
                {
                    field: "FacilityId",
                },
            ],
            statusFilters: [],
            isLoading: false,
            openSnack: false,
            snackMessage: "",
            btnActionedState: true,
            currentLocale: [],
            collapsedState: true,
            filter: {
                logic: "and",
                filters: [

                ],
            },
            dataState: initialDataState,

        };

        let self = this;

        this.gridRef = React.createRef();
    }

    currentLocale = [
        {
            language: "en-US",
            locale: "en",
        },
        {
            language: "es-ES",
            locale: "es",
        },
    ];
    state = {
        //dataResult: delinquentDashboardDataAccess.GetDashboardSnapshotSummary(dataState),

        currentLocale: this.currentLocale[0],
    };

    loadWorkingList() {

        var self = this;
        var isData = true;

        self.setState({ isLoading: true });

        delinquentDashboardDataAccess.GetDashboardSnapshotSummary().then(function (reportData) {
            if (reportData) {
                if (reportData.length > 0) {
                    isData = false;
                }
            }

            self.setState({ data: reportData, dataResult: processWithGroups(reportData, initialDataState), isLoading: false, isThereData: isData });
            //self.onGroupsToggle();
        });



    }



    exportExcel = () => {
        if (this._export !== null) {
            this._export.save();
        }
    };



    componentWillMount() {
        this.loadWorkingList();
        this.resizeGrid();
        window.onresize = this.resizeGrid;
    }

    componentDidUpdate(prevProps, prevState) {
        // Check if the data has changed to ensure the grid is loaded
        if (prevState.dataResult !== this.state.dataResult) {
            this.resizeGrid();
        }
    }

    resizeGrid = () => {
        const grid = document.getElementById('PriorityDashboardGrid');
        if (grid) {
            const windowHeight = window.innerHeight;
            const gridHeight = windowHeight - 225; 
            grid.style.height = `${gridHeight}px`;
            grid.style.display = 'none';
            grid.offsetHeight; // Trigger a reflow
            grid.style.display = '';
        }
    }

    dataStateChange = (event) => {
        const newDataState = processWithGroups(this.state.data, event.dataState);
        this.setState({
            dataResult: newDataState,
            dataState: event.dataState,
        });
    };

    expandChange = (event) => {

        let index = this.expandedValues.indexOf(event.dataItem.value)
        if (index >= 0) {
            this.expandedValues.splice(index, 1);
        } else {
            this.expandedValues.push(event.dataItem.value)
        }

        const isExpanded =
            event.dataItem.expanded === undefined
                ? event.dataItem.aggregates
                : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;
        this.setState({
            dataResult: {
                ...this.state.dataResult,
                data: [...this.state.dataResult.data],
            },
        });
    };

    getColumnHeader = (dayVal) => {
        return moment().subtract(dayVal, 'days').format('MM/DD/YYYY');
    };

    cellRender(tdElement, cellProps) {
        if (cellProps.rowType === "groupHeader" || cellProps.rowType === "groupFooter") {
          
            if (cellProps.field === "Day1") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day1.sum}
                    </td>
                );
            } else if (cellProps.field === "Day2") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day2.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day3") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day3.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day4") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day4.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day5") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day5.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day6") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day6.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day7") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day7.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day8") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day8.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day9") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day9.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day10") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day10.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day11") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day11.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day12") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day12.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day13") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day13.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day14") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day14.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day15") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day15.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day16") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day16.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day17") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day17.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day18") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day18.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day19") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day19.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day20") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day20.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day21") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day21.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day22") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day22.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day23") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day23.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day24") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day24.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day25") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day25.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day26") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day26.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day27") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day27.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day28") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day28.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day29") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day29.sum}
                    </td>
                );
            }
            else if (cellProps.field === "Day30") {
                return (
                    <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {cellProps.dataItem.aggregates.Day30.sum}
                    </td>
                );
            }
        } else if (cellProps.field === "Day30") {
            return (
                <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                    <Link style={{ textDecoration: 'underline' }} to={'/worklist/' + cellProps.dataItem.FacilityId} onClick={() => sessionStorage.setItem('tickler', cellProps.dataItem.DashboardCardId)}> {cellProps.dataItem.Day30}</Link>

                </td>
            );
        }
        return tdElement;
    }

    onGroupsToggle = () => {
        //  console.log('onGroupsToggle');
        const newCollapsedState = !this.state.collapsedState;

        if (this.state.collapsedState) {

            let newData = this.state.dataResult.data.map(item => {

                item.expanded = true;
                this.expandedValues.push(item.value);
                return item;

            })

            this.setState({
                dataResult: {
                    ...this.state.dataResult,
                    data: [...newData],
                },
            });
            ///  this.expandedValues = [];
        }
        else {
            this.expandedValues = [];
            let newData = this.state.dataResult.data.map(item => {

                item.expanded = false;

                return item;

            })

            this.setState({
                dataResult: {
                    ...this.state.dataResult,
                    data: [...newData],
                },
            });
        }
        this.setState({
            collapsedState: newCollapsedState,
        });
    };

    GroupMyHeaderCustomCell = (props) => (

        <CustomCell {...props} />
    );

    render() {
        const { openSnack, snackMessage } = this.state;
        //  console.log(this.state.dataResult.data);
        /* const newData = setExpandedState({
             data: this.state.dataResult.data,
             collapsedIds: this.state.collapsedState,
         });
         */
        this.state.dataResult.data.map(item => {
            let index = this.expandedValues.indexOf(item.value)
            if (index === -1) {
                item.expanded = false
                return item
            }
            return item
        })

        return (
            <LocalizationProvider language={this.state.currentLocale.language}>
                <IntlProvider locale={this.state.currentLocale.locale}>
                    <div>
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            open={openSnack}
                            autoHideDuration={3000}
                            onClose={this.handleSnackClose}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                            message={<span id="message-id">{snackMessage}</span>}
                        />

                        <ProgressIndicator showProgressIndicator={this.state.isLoading} progressTitle={'Please wait...'} progressText={'Loading data...'} />

                        <div>
                            <h2>Priority & Delinquent Dashboard</h2>
                            <div style={{ height: 10 }} />
                            <ExcelExport
                                data={this.state.data}
                                ref={(exporter) => {
                                    this._export = exporter;
                                }}
                            >
                                <Grid
                                    id="PriorityDashboardGrid"

                                    detail={CustomGridDetailRow}
                                    groupable={{
                                        footer: "visible",
                                    }}              

                                    groupable={true}
                                    reorderable={true}

                                    data={this.state.dataResult}
                                    {...this.state.dataState}
                                    onDataStateChange={this.dataStateChange}

                                    expandField="expanded"
                                    onExpandChange={this.expandChange}
                                    cellRender={this.cellRender}
                                    cells={{
                                        group: {
                                            groupHeader: this.GroupMyHeaderCustomCell,
                                        },
                                    }}
                                >
                                    <GridToolbar>

                                        <button
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={this.onGroupsToggle}
                                        >
                                            {this.state.collapsedState ? "Expand" : "Collapse"} Groups
                                        </button>
                                        <button
                                            title="Export to Excel"
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={this.exportExcel}
                                        >
                                            Export to Excel
                                        </button>

                                    </GridToolbar>

                                    <GridColumn field="CardName" title="Bucket" width="300px" />
                                    <GridColumn field="FacilityId" title="Client" width="300px" />
                                    <GridColumn field='Day1' title={this.getColumnHeader(29)} filterable={false} width="150px" />
                                    <GridColumn field='Day2' title={this.getColumnHeader(28)} filterable={false} width="150px" />
                                    <GridColumn field='Day3' title={this.getColumnHeader(27)} filterable={false} width="150px" />
                                    <GridColumn field='Day4' title={this.getColumnHeader(26)} filterable={false} width="150px" />
                                    <GridColumn field='Day5' title={this.getColumnHeader(25)} filterable={false} width="150px" />
                                    <GridColumn field='Day6' title={this.getColumnHeader(24)} filterable={false} width="150px" />
                                    <GridColumn field='Day7' title={this.getColumnHeader(23)} filterable={false} width="150px" />
                                    <GridColumn field='Day8' title={this.getColumnHeader(22)} filterable={false} width="150px" />
                                    <GridColumn field='Day9' title={this.getColumnHeader(21)} filterable={false} width="150px" />
                                    <GridColumn field='Day10' title={this.getColumnHeader(20)} filterable={false} width="150px" />
                                    <GridColumn field='Day11' title={this.getColumnHeader(19)} filterable={false} width="150px" />
                                    <GridColumn field='Day12' title={this.getColumnHeader(18)} filterable={false} width="150px" />
                                    <GridColumn field='Day13' title={this.getColumnHeader(17)} filterable={false} width="150px" />
                                    <GridColumn field='Day14' title={this.getColumnHeader(16)} filterable={false} width="150px" />
                                    <GridColumn field='Day15' title={this.getColumnHeader(15)} filterable={false} width="150px" />
                                    <GridColumn field='Day16' title={this.getColumnHeader(14)} filterable={false} width="150px" />
                                    <GridColumn field='Day17' title={this.getColumnHeader(13)} filterable={false} width="150px" />
                                    <GridColumn field='Day18' title={this.getColumnHeader(12)} filterable={false} width="150px" />
                                    <GridColumn field='Day19' title={this.getColumnHeader(11)} filterable={false} width="150px" />
                                    <GridColumn field='Day20' title={this.getColumnHeader(10)} filterable={false} width="150px" />
                                    <GridColumn field='Day21' title={this.getColumnHeader(9)} filterable={false} width="150px" />
                                    <GridColumn field='Day22' title={this.getColumnHeader(8)} filterable={false} width="150px" />
                                    <GridColumn field='Day23' title={this.getColumnHeader(7)} filterable={false} width="150px" />
                                    <GridColumn field='Day24' title={this.getColumnHeader(6)} filterable={false} width="150px" />
                                    <GridColumn field='Day25' title={this.getColumnHeader(5)} filterable={false} width="150px" />
                                    <GridColumn field='Day26' title={this.getColumnHeader(4)} filterable={false} width="150px" />
                                    <GridColumn field='Day27' title={this.getColumnHeader(3)} filterable={false} width="150px" />
                                    <GridColumn field='Day28' title={this.getColumnHeader(2)} filterable={false} width="150px" />
                                    <GridColumn field='Day29' title={this.getColumnHeader(1)} filterable={false} width="150px" />
                                    <GridColumn field='Day30' title={this.getColumnHeader(0)} filterable={false} width="150px" />
                                </Grid>
                            </ExcelExport>
                            <div style={{ height: 30 }} />
                        </div>
                    </div>
                </IntlProvider>
            </LocalizationProvider>
        );
    }
}
export default withStyles(styles, { withTheme: true })(PriorityDelinquentDashboard);