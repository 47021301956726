import axios from 'axios';
import Auth from './auth';
import { API_ROOT } from './apiConfig';


export default class PASSystemDataAccess {

    getPASSystems() {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetPASSystems"
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    sessionStorage.setItem('pasSystemList', JSON.stringify(response.data));
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getPASReportsBySystemId(systemId) {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetPASReportsBySystemId", parameters: { systemId: systemId }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    sessionStorage.setItem('pasReportList', JSON.stringify(response.data));
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    getPASSettings() {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "GetEOMSettings"
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    sessionStorage.setItem('passSettings', JSON.stringify(response.data[0]));
                    resolve(response.data[0]);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    PASWizard_Log(facilityId, messageLog) {
        const auth = new Auth();

        return new Promise((resolve, reject) => {

            let reqBody = {
                account: "zuludatabase", command: "PASWizard_Log", parameters: { facilityId: facilityId, executedBy: auth.getUserId(), message: messageLog }
            };

            axios.post(`${API_ROOT}/api/v1/qtype/Query`, reqBody,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth.getToken()}`
                    }
                }).then(function (response) {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }
}
