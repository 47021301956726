import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, GridColumn, GridToolbar, GridDetailRow } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { IntlProvider, load, LocalizationProvider, loadMessages, IntlService } from "@progress/kendo-react-intl";
import Snackbar from '@material-ui/core/Snackbar';
import ProgressIndicator from './ProgressIndicator';
import Auth from '../services/auth';
import ProductivityDashboardDataAccess from '../services/ProductivityDashboardDataAccess';
import { groupBy, filterBy, process, orderBy } from "@progress/kendo-data-query";
import { Link } from 'react-router-dom';
import moment from 'moment';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { setExpandedState, getGroupIds, setGroupIds } from "@progress/kendo-react-data-tools";
import orders from "./orders.json";
import PropTypes from 'prop-types';

class CustomGridDetailRow extends GridDetailRow {
    render() {
        const { dataItem } = this.props;
        const detailData = dataItem.StatusCodeDetail;
        if (detailData) {
            return (
                <div>
                    <Grid data={orderBy(detailData, [{ field: "Count", dir: "desc" }])} />
                </div>
            );
        }
        return null;
    }
}

const auth = new Auth();
const productivityDashboardDataAccess = new ProductivityDashboardDataAccess();

const CustomCell = ({ tdProps = {}, dataItem, field, color, children }) => {
    tdProps = tdProps || {};

    if (tdProps.colSpan != null) {
        tdProps.colSpan = 7;
    }

    return (
        <td {...tdProps} style={{ backgroundColor: color }}>
            {children && (
                <p className="k-reset">
                    {children.props.children[0]}
                    {dataItem[field]}
                </p>
            )}
        </td>
    );
};

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(1),
    }),
    inputLabel: {
        paddingLeft: 2,
        marginLeft: 18,
        marginTop: -10,
        width: '100%',
    },
    formControl: {
        width: 150,
        paddingLeft: 5,
    },
});

const locales = [
    { language: "en-US", locale: "en" },
    { language: "es-ES", locale: "es" },
];

const aggregates = Array.from({ length: 30 }, (_, i) => ({
    field: `Day${i + 1}`,
    aggregate: "sum",
}));

const initialDataState = {
    group: [
        { field: "System" },
        { field: "Organization" },
        { field: "UserName" },
        { field: "ActivityType" },
        { field: "Facility" },
    ],
};

const processWithGroups = (data, dataState) => {
    const groups = dataState.group.map(group => ({
        ...group,
        aggregates,
    }));

    const newDataState = process(data, { ...dataState, group: groups });
    setGroupIds({ data: newDataState.data, group: groups });

    const setExpandedFalse = (item, level) => {
        if (level > 5) return;
        item.expanded = false;
        if (item.items) {
            item.items.forEach(subItem => setExpandedFalse(subItem, level + 1));
        }
    };

    newDataState.data.forEach(item => setExpandedFalse(item, 1));

    return newDataState;
};

class ProductivityDashboard extends Component {
    constructor(props) {
        super(props);

        this._export = null;
        this.expandedValues = [];

        this.state = {
            data: [],
            dataResult: { data: [] },
            group: initialDataState.group,
            statusFilters: [],
            isLoading: false,
            showLinearProgress: true,
            openSnack: false,
            snackMessage: "",
            btnActionedState: true,
            currentLocale: locales[0],
            collapsedState: true,
            collapsedStateByGroup: {
                System: true,
                Organization: true,
                UserName: true,
                ActivityType: true,
                Facility: true,
            },
            filter: { logic: "and", filters: [] },
            dataState: initialDataState,
        };

        this.gridRef = React.createRef();
    }

    componentDidMount() {
        this.loadWorkingList();
        this.resizeGrid();
        window.onresize = this.resizeGrid;
    }

    componentDidUpdate(prevProps, prevState) {      
        if (prevState.dataResult !== this.state.dataResult) {           
            this.resizeGrid();
        }
    }

    loadWorkingList = () => {
        this.setState({ isLoading: true });

        productivityDashboardDataAccess.GetProductivityDashboardSummary().then(reportData => {
            const isData = reportData && reportData.length > 0;
            this.setState({
                data: reportData,
                dataResult: processWithGroups(reportData, initialDataState),
                isLoading: false,
                isThereData: isData,
            });
        });
    };

    exportExcel = () => {
        if (this._export) {
            this._export.save();
        }
    };

    resizeGrid = () => {
        const grid = document.getElementById('ProductivityDashboardGrid');
        if (grid) {
            const windowHeight = window.innerHeight;
            const gridHeight = windowHeight - 225;
            grid.style.height = `${gridHeight}px`;
            grid.style.display = 'none';
            grid.offsetHeight;
            grid.style.display = '';
        }
    };

    dataStateChange = (event) => {
        const newDataState = processWithGroups(this.state.data, event.dataState);
        this.setState({
            dataResult: newDataState,
            dataState: event.dataState,
        });
    };

    expandChange = (event) => {
        const { dataItem } = event;
        const index = this.expandedValues.indexOf(dataItem.value);
        if (index >= 0) {
            this.expandedValues.splice(index, 1);
        } else {
            this.expandedValues.push(dataItem.value);
        }

        dataItem.expanded = !dataItem.expanded;
        this.setState({
            dataResult: {
                ...this.state.dataResult,
                data: [...this.state.dataResult.data],
            },
        });
    };

    getColumnHeader = (dayVal) => moment().subtract(dayVal, 'days').format('MM/DD/YYYY');

    cellRender = (tdElement, cellProps) => {
        const { rowType, field, columnIndex, dataItem } = cellProps;
        if (rowType === "groupHeader" || rowType === "groupFooter") {
            const aggregate = dataItem.aggregates[field]?.sum;
            if (aggregate !== undefined) {
                return (
                    <td aria-colindex={columnIndex} role="gridcell">
                        {aggregate}
                    </td>
                );
            }
        }
        return tdElement;
    };

    onGroupsToggle = (field) => {
        this.setState({ isLoading: true, showLinearProgress: false });
        const newCollapsedState = !this.state.collapsedStateByGroup[field];;
        setTimeout(() => {
            const setExpanded = (item, level, maxLevel) => {
                if (newCollapsedState) {
                    item.expanded = false;
                } else if (level <= maxLevel) {
                    item.expanded = !newCollapsedState;
                }
                if (item.items) {
                    item.items.forEach(subItem => setExpanded(subItem, level + 1, maxLevel));
                }
            };

            let maxLevel = 0;
            let newCollapsedStateByGroup = { ...this.state.collapsedStateByGroup };

            switch (field) {
                case 'System':
                    maxLevel = 1;
                    newCollapsedStateByGroup = {
                        ...newCollapsedStateByGroup,
                        System: newCollapsedState,
                        Organization: newCollapsedState ? newCollapsedState : this.state.collapsedStateByGroup.Organization,
                        UserName: newCollapsedState ? newCollapsedState : this.state.collapsedStateByGroup.UserName,
                        ActivityType: newCollapsedState ? newCollapsedState : this.state.collapsedStateByGroup.ActivityType,
                        Facility: newCollapsedState ? newCollapsedState : this.state.collapsedStateByGroup.Facility,
                    };
                    break;
                case 'Organization':
                    maxLevel = 2;
                    newCollapsedStateByGroup = {
                        ...newCollapsedStateByGroup,
                        System: newCollapsedState,
                        Organization: newCollapsedState,
                        UserName: newCollapsedState ? newCollapsedState : this.state.collapsedStateByGroup.UserName,
                        ActivityType: newCollapsedState ? newCollapsedState : this.state.collapsedStateByGroup.ActivityType,
                        Facility: newCollapsedState ? newCollapsedState : this.state.collapsedStateByGroup.Facility,
                    };
                    break;
                case 'UserName':
                    maxLevel = 3;
                    newCollapsedStateByGroup = {
                        ...newCollapsedStateByGroup,
                        System: newCollapsedState,
                        Organization: newCollapsedState,
                        UserName: newCollapsedState,
                        ActivityType: newCollapsedState ? newCollapsedState : this.state.collapsedStateByGroup.ActivityType,
                        Facility: newCollapsedState ? newCollapsedState : this.state.collapsedStateByGroup.Facility,
                    };
                    break;
                case 'ActivityType':
                    maxLevel = 4;
                    newCollapsedStateByGroup = {
                        ...newCollapsedStateByGroup,
                        System: newCollapsedState,
                        Organization: newCollapsedState,
                        UserName: newCollapsedState,
                        ActivityType: newCollapsedState,
                        Facility: newCollapsedState ? newCollapsedState : this.state.collapsedStateByGroup.Facility,
                    };
                    break;
                case 'Facility':
                    maxLevel = 5;
                    newCollapsedStateByGroup = {
                        ...newCollapsedStateByGroup,
                        System: newCollapsedState,
                        Organization: newCollapsedState,
                        UserName: newCollapsedState,
                        ActivityType: newCollapsedState,
                        Facility: newCollapsedState,
                    };
                    break;
                default:
                    maxLevel = 0;
            }


            let newData = this.state.dataResult.data.map(item => {
                setExpanded(item, 1, maxLevel);
                return item;
            });

            this.setState({
                dataResult: {
                    ...this.state.dataResult,
                    data: [...newData],
                },
                collapsedStateByGroup: newCollapsedStateByGroup,
                isLoading: false,
            });
        }, 1000);
    };

    GroupMyHeaderCustomCell = (props) => (
        <CustomCell {...props} />
    );

    render() {
        const { openSnack, snackMessage } = this.state;

        return (
            <LocalizationProvider language={this.state.currentLocale.language}>
                <IntlProvider locale={this.state.currentLocale.locale}>
                    <div>
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            open={openSnack}
                            autoHideDuration={3000}
                            onClose={this.handleSnackClose}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                            message={<span id="message-id">{snackMessage}</span>}
                        />

                        <ProgressIndicator showProgressIndicator={this.state.isLoading} showLinearProgress={this.state.showLinearProgress}  progressTitle={'Please wait...'} progressText={'Loading data...'} />
                        
                        <div>
                            <h2>Productivity Dashboard</h2>
                            <div style={{ height: 10 }} />
                            <ExcelExport
                                data={this.state.data}
                                ref={(exporter) => {
                                    this._export = exporter;
                                }}
                            >
                                <Grid
                                    id="ProductivityDashboardGrid"

                                    detail={CustomGridDetailRow}
                                    groupable={{
                                        footer: "visible",
                                    }}
                                    groupable={true}
                                    reorderable={true}
                                    data={this.state.dataResult}
                                    {...this.state.dataState}
                                    onDataStateChange={this.dataStateChange}
                                    expandField="expanded"
                                    onExpandChange={this.expandChange}
                                    cellRender={this.cellRender}
                                    cells={{
                                        group: {
                                            groupHeader: this.GroupMyHeaderCustomCell,
                                        },
                                    }}
                                >
                                    <GridToolbar>

                                        <button
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={() => this.onGroupsToggle('System')}
                                        >
                                            {this.state.collapsedStateByGroup.System ? "Expand" : "Collapse"} System
                                        </button>
                                        <button
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={() => this.onGroupsToggle('Organization')}
                                        >
                                            {this.state.collapsedStateByGroup.Organization ? "Expand" : "Collapse"} Organization
                                        </button>
                                        <button
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={() => this.onGroupsToggle('UserName')}
                                        >
                                            {this.state.collapsedStateByGroup.UserName ? "Expand" : "Collapse"} User
                                        </button>
                                        <button
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={() => this.onGroupsToggle('ActivityType')}
                                        >
                                            {this.state.collapsedStateByGroup.ActivityType ? "Expand" : "Collapse"} Activity Type
                                        </button>
                                        <button
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={() => this.onGroupsToggle('Facility')}
                                        >
                                            {this.state.collapsedStateByGroup.Facility ? "Expand" : "Collapse"} Facility
                                        </button>
                                        <button
                                            title="Export to Excel"
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={this.exportExcel}
                                        >
                                            Export to Excel
                                        </button>

                                    </GridToolbar>

                                    <GridColumn field="System" title="System" width="125px" />
                                    <GridColumn field="Organization" title="Organization" width="150px" />
                                    <GridColumn field="UserName" title="User" width="150px" />
                                    <GridColumn field="ActivityType" title="Activity Type" width="150px" />
                                    <GridColumn field="Facility" title="Client" width="200px" />
                                    <GridColumn field='Day1' title={this.getColumnHeader(29)} filterable={false} width="150px" />
                                    <GridColumn field='Day2' title={this.getColumnHeader(28)} filterable={false} width="150px" />
                                    <GridColumn field='Day3' title={this.getColumnHeader(27)} filterable={false} width="150px" />
                                    <GridColumn field='Day4' title={this.getColumnHeader(26)} filterable={false} width="150px" />
                                    <GridColumn field='Day5' title={this.getColumnHeader(25)} filterable={false} width="150px" />
                                    <GridColumn field='Day6' title={this.getColumnHeader(24)} filterable={false} width="150px" />
                                    <GridColumn field='Day7' title={this.getColumnHeader(23)} filterable={false} width="150px" />
                                    <GridColumn field='Day8' title={this.getColumnHeader(22)} filterable={false} width="150px" />
                                    <GridColumn field='Day9' title={this.getColumnHeader(21)} filterable={false} width="150px" />
                                    <GridColumn field='Day10' title={this.getColumnHeader(20)} filterable={false} width="150px" />
                                    <GridColumn field='Day11' title={this.getColumnHeader(19)} filterable={false} width="150px" />
                                    <GridColumn field='Day12' title={this.getColumnHeader(18)} filterable={false} width="150px" />
                                    <GridColumn field='Day13' title={this.getColumnHeader(17)} filterable={false} width="150px" />
                                    <GridColumn field='Day14' title={this.getColumnHeader(16)} filterable={false} width="150px" />
                                    <GridColumn field='Day15' title={this.getColumnHeader(15)} filterable={false} width="150px" />
                                    <GridColumn field='Day16' title={this.getColumnHeader(14)} filterable={false} width="150px" />
                                    <GridColumn field='Day17' title={this.getColumnHeader(13)} filterable={false} width="150px" />
                                    <GridColumn field='Day18' title={this.getColumnHeader(12)} filterable={false} width="150px" />
                                    <GridColumn field='Day19' title={this.getColumnHeader(11)} filterable={false} width="150px" />
                                    <GridColumn field='Day20' title={this.getColumnHeader(10)} filterable={false} width="150px" />
                                    <GridColumn field='Day21' title={this.getColumnHeader(9)} filterable={false} width="150px" />
                                    <GridColumn field='Day22' title={this.getColumnHeader(8)} filterable={false} width="150px" />
                                    <GridColumn field='Day23' title={this.getColumnHeader(7)} filterable={false} width="150px" />
                                    <GridColumn field='Day24' title={this.getColumnHeader(6)} filterable={false} width="150px" />
                                    <GridColumn field='Day25' title={this.getColumnHeader(5)} filterable={false} width="150px" />
                                    <GridColumn field='Day26' title={this.getColumnHeader(4)} filterable={false} width="150px" />
                                    <GridColumn field='Day27' title={this.getColumnHeader(3)} filterable={false} width="150px" />
                                    <GridColumn field='Day28' title={this.getColumnHeader(2)} filterable={false} width="150px" />
                                    <GridColumn field='Day29' title={this.getColumnHeader(1)} filterable={false} width="150px" />
                                    <GridColumn field='Day30' title={this.getColumnHeader(0)} filterable={false} width="150px" />
                                </Grid>
                            </ExcelExport>
                            <div style={{ height: 30 }} />
                        </div>
                    </div>
                </IntlProvider>
            </LocalizationProvider>
        );
    }
}
ProductivityDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductivityDashboard);