import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Auth from '../services/auth';
import AppInsights from './AppInsights';
import FacilityDataAccess from '../services/facilityDataAccess';
import EOMSystemDataAccess from '../services/eomSystemDataAccess';
import WorkFlowManagementDataAccess from '../services/workFlowManagementDataAccess';
import FormControl from '@material-ui/core/FormControl';
import { InputLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ProgressIndicator from './ProgressIndicator';
import CheckIconCellRenderer from './CheckIconCellRenderer.js';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { BlobServiceClient, BlobItem } from "@azure/storage-blob";

const eomSystemDataAccess = new EOMSystemDataAccess();
const facilityDataAccess = new FacilityDataAccess();
const workFlowManagementDataAccess = new WorkFlowManagementDataAccess();
const auth = new Auth();

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(1),
    }),
    inputLabel: {
        paddingLeft: 2,
        marginLeft: 18,
        marginTop: -10,
        width: '100%',
    },
    formControl: {
        width: '100%',
        paddingLeft: 5,
    },
});

class EomWizard extends Component {

    displayName = EomWizard.name

    constructor(props) {
        super(props);

        // Initial state
        this.state = {
            systemNames: [],
            systemId: "",
            data: [],
            isLoading: false,
            facilityNames: [],
            facilityId: "",
            eomReports: [],
            selectedSystem: null,
            blobStorageClient: null,
            workflow: null,
        };
        this.gridRef = React.createRef();

    }

    componentDidMount() {
        // Accessing the passed parameters
        const { systemId, facilityId } = this.props.location.state || {};
        console.log(systemId, facilityId)

        // Fetch blob storage URL from the database
        this.fetchBlobStorageUrl();

        // Load initial data when the component is mounted
        this.loadEOMSystems(systemId, facilityId);
    }

    handleError = (error) => {
        // Log the error to Application Insights
        AppInsights.trackException({ exception: error });
    };

    fetchBlobStorageUrl() {
        var self = this;
        try {
            eomSystemDataAccess.getEOMSettings().then(function (settings) {
                if (settings) {
                    const blobStorageUrl = settings.BlobService;

                    const blobServiceClient = new BlobServiceClient(blobStorageUrl);

                    self.setState({ blobStorageClient: blobServiceClient });
                } else {
                    self.handleError('Error fetching Blob Storage URL: -URL not defined');
                }
            });
        } catch (error) {
            self.handleError('Error fetching Blob Storage URL: -' + error);
        }

    };

    // Load EOM Systems
    loadEOMSystems(selectedSystemId, selectedFacilityId) {
        var self = this;
        self.setState({ isLoading: true });
        try {
            eomSystemDataAccess.getEOMSystems().then(function (rs) {
                if (rs) {
                    self.setState({ systemNames: rs, isLoading: false });

                    // If a systemId is passed, set it as the selected system
                    if (selectedSystemId) {
                        const selectedSystem = rs.find((system) => system.SystemId === selectedSystemId);
                        if (selectedSystem) {
                            self.setState({ systemId: selectedSystemId, selectedSystem }, () => {
                                // After setting the selected system, load reports and facilities
                                self.loadEomReports();
                                self.loadEomFacilities(selectedFacilityId);
                            });
                        }
                    } else {
                        // Default to the first system if no systemId is passed
                        self.setState({ systemId: rs[0].SystemId, selectedSystem: rs[0] }, () => {
                            self.loadEomReports();
                            self.loadEomFacilities();
                        });
                    }
                } else {
                    self.handleError('EOM Wizard No Systems Configured');
                    self.setState({ isLoading: false });
                }
            });
        } catch (error) {
            self.handleError(error);
            window.alert('EOM Wizard No Systems Configured');
            self.setState({ isLoading: false });
            return;
        }
    }

    // Load EOM reports based on the selected system
    loadEomReports() {
        var self = this;
        self.setState({ isLoading: true });
        try {
            eomSystemDataAccess.getReportsBySystemId(self.state.selectedSystem.SystemId)
                .then(result => {
                    self.setState({ eomReports: result, isLoading: false });
                    if (!result) {
                        self.handleError('No Reports for the system: ' + self.state.selectedSystem.SystemId);
                    }
                });
        } catch (error) {
            self.handleError(error);
            window.alert('No Reports for this system');
            self.setState({ isLoading: false });
            return;
        }
    }

    // Load facilities based on the selected system
    loadEomFacilities(selectedFacilityId) {
        var self = this;
        self.setState({ isLoading: true });
        try {
            facilityDataAccess.getFacilityBySystemId(self.state.selectedSystem.SystemId)
                .then(result => {
                    //console.log(selectedFacilityId);
                    //console.log(result);
                    self.setState({ facilityNames: result, isLoading: false });

                    // If a facilityId is passed, set it as the selected facility
                    if (selectedFacilityId) {
                        const selectedFacility = result.find((facility) => facility.Account === selectedFacilityId);
                        if (selectedFacility) {
                            self.setState({ facilityId: selectedFacilityId });
                        }
                    }

                    if (!result) {
                        self.handleError('No Facilities for the system: ' + self.state.selectedSystem.SystemId);
                    }
                });
        } catch (error) {
            self.handleError(error);
            window.alert('No Facilities for this system');
            self.setState({ isLoading: false });
            return;
        }
    }

    // Load Facility Workflow
    loadWorkflow(facilityId) {
        var self = this;
        try {
            workFlowManagementDataAccess.getGetWorkflowByFacility(facilityId)
                .then(result => {
                    //console.log(result);
                    self.setState({ workflow: result });
                    if (!result) {
                        console.error('Workflow is undefined after loadWorkflow call.');
                    }
                });
        } catch (error) {
            self.handleError(error);
        }
    }

    // Load Facility Workflow
    addWorkflow(facilityId) {
        let self = this;
        try {
            workFlowManagementDataAccess.addInitialWorkflowFacility(facilityId).then(function (rs) {
                console.log('workflow added');
            });
        } catch (error) {
            self.handleError(error);
        }
    }

    // Update Worflow log
    updateWorkFlowLog(facilityId) {
        let self = this;
        try {
            workFlowManagementDataAccess.EOMWizard_Log(facilityId, 'EOM Wizard Report Uploaded').then(function (rs) {
                console.log('workflow log updated');
            });
        } catch (error) {
            self.handleError(error);
        }
    }

    // Event handler for changing the selected facility
    onFacilityChange = () => event => {
        this.setState({ facilityId: event.target.value });
    }

    // Event handler for changing the selected system
    onSystemChange = () => event => {
        var self = this;
        try {
            const selectedSystemId = event.target.value;
            const selectedSystem = self.state.systemNames.find((system) => system.SystemId === event.target.value);

            self.setState(
                {
                    systemId: selectedSystemId,
                    selectedSystem: selectedSystem,
                    facilityId: ''
                },
                () => {
                    self.loadEomReports();
                    self.loadEomFacilities();
                    // Reset selected files when the system changes
                    const resetSelectedFiles = (report) => {
                        if (report.AllowMultipleFiles) {
                            report.SelectedFiles = [];
                        } else {
                            report.SelectedFile = null;
                        }
                    };

                    // Apply the reset to each report
                    self.state.eomReports.forEach(resetSelectedFiles);

                    // Clear the input file fields
                    const fileInputs = document.querySelectorAll('input[type="file"]');
                    fileInputs.forEach(input => {
                        input.value = null;
                    });

                    self.setState(this.state);
                }
            );
        } catch (error) {
            self.handleError(error);
        }
    }


    // Upload selected EOM files to Azure Storage
    uploadEomFiles = async () => {
        var self = this;

        self.setState({ isLoading: true });
        try {
            // Check if eomReports or Reports is undefined before proceeding
            if (!self.state.eomReports) {
                self.handleError('eomReports or Reports is undefined.');
                self.setState({ isLoading: false });
                return;
            }

            // Check if a facility is chosen
            if (!self.state.facilityId) {
                window.alert('Please choose a facility.');
                self.setState({ isLoading: false });
                return;
            }

            // Check if all reports have selected files
            const missingFiles = self.state.eomReports.filter(report => {
                if (report.AllowMultipleFiles) {
                    return !report.SelectedFiles || report.SelectedFiles.length === 0;
                } else {
                    return !report.SelectedFile;
                }
            });


            if (missingFiles.length > 0) {
                // Display an alert if any selected file has an extension not allowed
                window.alert(`Please select a file for the following reports:\n${missingFiles.map(report => report.ReportDisplayName).join('\n')}`);
                self.setState({ isLoading: false });
                return;
            }

            // Check if any selected file has an extension not allowed
            const invalidFiles = self.state.eomReports.filter(report => {
                if (report.AllowMultipleFiles) {
                    // Check if any of the selected files has an invalid extension
                    return report.SelectedFiles.some(selectedFile =>
                        !self.isFileExtensionAllowed(selectedFile.name, report.AllowedFileTypes)
                    );
                } else {
                    // Check if the selected file has an invalid extension
                    return report.SelectedFile && !self.isFileExtensionAllowed(report.SelectedFile.name, report.AllowedFileTypes);
                }
            });

            if (invalidFiles.length > 0) {
                // Display an alert if any selected file has an extension not allowed
                window.alert(`File extension not allowed for the following reports:\n${invalidFiles.map(report => report.ReportDisplayName).join('\n')}`);
                self.setState({ isLoading: false });
                return;
            }

            // Construct the ContainerClient
            var containerClient = self.state.blobStorageClient.getContainerClient(self.state.selectedSystem.Container);

            let uploadedFiles;

            if (self.state.selectedSystem.SystemId === 'SIS Complete' || self.state.selectedSystem.SystemId === 'HST') {
                uploadedFiles = { data: [] };

            } else {
                uploadedFiles = [];
            }

            // Use for...of loop to handle asynchronous tasks properly
            for (const report of self.state.eomReports) {

                if ((report.AllowMultipleFiles && report.SelectedFiles.length > 0) || (!report.AllowMultipleFiles && report.SelectedFile)) {

                    const selectedFiles = report.AllowMultipleFiles ? report.SelectedFiles : [report.SelectedFile];

                    for (let index = 0; index < selectedFiles.length; index++) {
                        const selectedFile = selectedFiles[index];

                        // Replace [yyyy] in the ReportFileName with the current year
                        const currentYear = new Date().getFullYear();
                        report.ReportFileName = report.ReportFileName.replace(/\[yyyy\]/g, currentYear);

                        // Replace [yyyyMMdd] in the ReportFileName with the current date
                        const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, ''); // Format: yyyyMMdd
                        report.ReportFileName = report.ReportFileName.replace(/\[yyyyMMdd\]/g, currentDate);

                        // Replace [hhmmss] in the ReportFileName with the current time
                        const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false }).replace(/:/g, ''); // Format: hhmmss
                        report.ReportFileName = report.ReportFileName.replace(/\[hhmmss\]/g, currentTime);

                        // Extract file name and extension from ReportFileName
                        const [fileName, fileExtension] = report.ReportFileName.split('.');

                        // Add an index number to the blob name if there are multiple files
                        if (report.AllowMultipleFiles) {
                            selectedFile.finalFileName = `${fileName}_${index + 1}.${fileExtension}`;
                        } else {
                            selectedFile.finalFileName = report.ReportFileName;
                        }

                        // Construct the blob name by appending the report file name to the directory path
                        var blobName = `${self.state.facilityId}/${selectedFile.finalFileName}`;

                        var blobClient = containerClient.getBlockBlobClient(blobName);

                        const options = {
                            blobHTTPHeaders: {
                                blobContentType: selectedFile.type
                            }
                        };

                        try {
                            // Upload the file to Azure Storage
                            await blobClient.uploadBrowserData(selectedFile, options);

                            self.loadWorkflow(self.state.facilityId);
                            if (typeof this.state.workflow === 'undefined' || this.state.workflow === null) {
                                self.addWorkflow(self.state.facilityId);

                                const tempWorkflow = {
                                    WorkflowId: '{}'
                                };

                                self.setState({ workflow: tempWorkflow });
                            }


                            if (report.SystemId === 'SIS Complete' || report.SystemId === 'HST') {



                                // Add the uploaded file details to the list
                                uploadedFiles.data.push({
                                    _id: {
                                        $oid: "EOMWizard_" + report.SystemId + '_' + self.state.facilityId + '_' + selectedFile.finalFileName
                                    },
                                    workflow_id: self.state.workflow.WorkflowId,
                                    external_client_id: "eomwizard",
                                    system_name: report.SystemId,
                                    mac_address: "sn.rcmbrain.com",
                                    file_name: selectedFile.finalFileName,
                                    upload_status: true,
                                    download_status: false,
                                    rcmb_client_id: "eomwizard",
                                    source: "EOMWizard",
                                    storage_account: "strcmeomdatalake",
                                    container: self.state.selectedSystem.Container,
                                    error_details: null,
                                    date: currentDate,
                                    created_date: {
                                        $date: null
                                    },
                                    workflow_execute_dateTime: {
                                        $date: null
                                    }
                                });

                            } else {
                                // Add the uploaded file details to the list
                                uploadedFiles.push({
                                    Id: "EOMWizard_" + report.SystemId + '_' + self.state.facilityId + '_' + selectedFile.finalFileName,
                                    Name: selectedFile.finalFileName,
                                    DisplayName: selectedFile.finalFileName,
                                    Path: "EOMWizard/" + report.SystemId + '/' + blobName,
                                    LastModified: selectedFile.lastModifiedDate,
                                    Size: selectedFile.size,
                                    MediaType: selectedFile.type,
                                    IsFolder: false,
                                    ETag: "",
                                    FileLocator: "",
                                    LastModifiedBy: null
                                });
                            }


                        } catch (error) {
                            self.handleError('Error uploading file: -' + error);
                            // Display an alert in case of upload failure
                            window.alert('Error uploading files. Please try again.');
                            self.setState({ isLoading: false });
                            return;
                        }
                    }
                }
            }

            // Reset the file input after successful upload
            const fileInputs = document.querySelectorAll('input[type="file"]');
            fileInputs.forEach(input => input.value = null);

            // Generate a JSON file with the list of uploaded files
            const jsonContent = JSON.stringify(uploadedFiles, null, 2);
            const jsonblobName = self.state.facilityId + '/eomwizard.json';
            const jsonBlobClient = containerClient.getBlockBlobClient(jsonblobName);

            try {
                // Upload the JSON file to Azure Storage
                await jsonBlobClient.uploadBrowserData(jsonContent, { blobHTTPHeaders: { blobContentType: 'application/json' } });
            } catch (error) {
                self.handleError('Error uploading JSON file: -' + error);
                // Display an alert in case of JSON file upload failure
                window.alert('Error uploading JSON file. Please try again.');
                self.setState({ isLoading: false });
                return;
            }

            self.updateWorkFlowLog(self.state.facilityId);

            // Display a success message when all files are successfully uploaded
            window.alert('All files uploaded successfully!');
        } catch (error) {
            self.handleError('Error uploading files: -' + error);
            window.alert('Error uploading files. Please try again.');
            self.setState({ isLoading: false });
            return;
        }
        self.setState({ isLoading: false });
    };

    // Function to check if the file extension is allowed
    isFileExtensionAllowed = (fileName, allowedFileTypes) => {
        if (allowedFileTypes === '*') {
            // If AllowedFileTypes is '*', any file type is allowed
            return true;
        }

        const fileExtension = fileName.split('.').pop().toLowerCase(); // Get the lowercase file extension
        const allowedExtensions = allowedFileTypes.split(',').map(ext => ext.trim().toLowerCase().replace('.', '')); // Convert allowed extensions to lowercase and remove '.'

        return allowedExtensions.includes(fileExtension);
    };


    render() {

        const { classes } = this.props;

        // Redirect to home if redirectToHome is true
        if (!auth.isInEOMWizardGroup()) {
            return <Redirect to="/" />;
        }

        const columnDefs = [];
        const defaultColDef = { resizable: true };


        return (
            <div>
                {/*Progress indicator component*/}

                <ProgressIndicator showProgressIndicator={this.state.isLoading} progressTitle={'Please wait...'} progressText={'Loading data...'} />
                <h2>EOM Wizard</h2>
                <Grid item xs={12} container justifyContent="flex-start">
                    <a
                        href="https://surgicalnotes1.sharepoint.com/rcmclient/SitePages/End-of-Month-(EoM).aspx"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                        EOM SOP
                    </a>&nbsp;<HelpOutlineIcon style={{ color: 'gray', fontSize: 16, cursor: 'pointer' }} />
                </Grid>
                <Grid item xs={12} container justifyContent="flex-start">
                    <p style={{ fontStyle: 'italic', }}>*EOM Wizard can only be used on the 1st day of the month forward.  If your center closes before then (ex. 6/28), please upload the PAS reports to the EOM Wizard on or after the 1st of the month (7/1).</p>
                </Grid>                
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={3}>
                        <Grid container className={classes.root} spacing={3}>
                            <Grid item xs={12}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel
                                        id="System"
                                        className={classes.inputLabel}>System</InputLabel >
                                    <Select
                                        value={this.state.systemId || ''}
                                        onChange={this.onSystemChange()}
                                        id="System"
                                        labelId="System"
                                        variant="outlined"
                                        margin="dense"
                                        label="System"
                                        fullWidth
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        {this.state.systemNames.map((option) => (
                                            <MenuItem key={option.SystemId} value={option.SystemId}>{option.SystemName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl></Grid>
                            <Grid item xs={12}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel
                                        id="Facility"
                                        className={classes.inputLabel}>Facility</InputLabel >
                                    <Select
                                        value={this.state.facilityId || ''}
                                        onChange={this.onFacilityChange()}
                                        id="Facility"
                                        labelId="Facility"
                                        variant="outlined"
                                        margin="dense"
                                        label="Facility"
                                        fullWidth
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        {this.state.facilityNames.map((option) => (
                                            <MenuItem key={option.Facility} value={option.Account}>{option.Facility}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        {this.state.eomReports && this.state.eomReports.length > 0 && <div><TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Report Name</TableCell>
                                        <TableCell>File Format</TableCell>
                                        <TableCell align="right">Report Parameters</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.eomReports.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {row.ReportDisplayName}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.AllowedFileTypes}
                                            </TableCell>
                                            <TableCell align="right">
                                                <div dangerouslySetInnerHTML={{ __html: row.ReportDetails.replace(/;/g, '<br />') }} />
                                            </TableCell>
                                            <TableCell align="right" style={{ maxWidth: '300px' }}>
                                                <input
                                                    accept={row.AllowedFileTypes}
                                                    onChange={(event) => {
                                                        const files = Array.from(event.target.files);

                                                        if (row.AllowMultipleFiles) {
                                                            row.SelectedFiles = files;
                                                        } else {
                                                            row.SelectedFile = files.length > 0 ? files[0] : null;
                                                        }
                                                        this.setState(this.state);
                                                    }}
                                                    onClick={(event) => {
                                                        const files = Array.from(event.target.files);

                                                        if (row.AllowMultipleFiles) {
                                                            row.SelectedFiles = files;
                                                        } else {
                                                            row.SelectedFile = files.length > 0 ? files[0] : null;
                                                        }
                                                    }}
                                                    type="file"
                                                    multiple={row.AllowMultipleFiles}  // Allow multiple file selection
                                                />
                                                {row.SelectedFiles && row.SelectedFiles.length > 0 && (
                                                    row.SelectedFiles.map((selectedFile, index) => (
                                                        <div key={index} style={{ textAlign: 'left' }}>
                                                            {selectedFile.name}
                                                        </div>
                                                    ))
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>


                        </div>}
                    </Grid>
                    <Grid item xs={1} container
                        direction="column"
                        justifyContent="flex-end"
                        alignItems="flex-end">
                        <Button disabled={this.state.eomReports && this.state.eomReports.filter((report) => report && report.SelectedFile === null).length !== 0} variant="contained" onClick={() => { this.uploadEomFiles(); }}>Submit</Button>
                    </Grid>
                </Grid>

                {/*Grid component container*/}

                <div className="ag-theme-alpine" style={{ height: '75vh', width: '100%' }}>


                    {/*Dropdown component*/}



                    <div style={{ height: 10 }} />



                </div>
                <div style={{ height: 80 }} />
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(EomWizard);
